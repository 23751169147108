import { TonConnectButton, useTonAddress } from "@tonconnect/ui-react";
import "./styles.css";
import { useEffect } from "react";
import getStore from "../../store/get-store";
import axios from "axios";
const WalletConnectComp = () => {
    const userFriendlyAddress = useTonAddress();
    const jwt = getStore().getState()["auth/app"].data.jwt;

    const addWallet = async (walletData: { address: string; type: string }) => {
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/wallet/addresses`, {
            address: walletData.address,
            type: walletData.type
        }, {
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        });
    
        return response;
    }
    
    useEffect(() => {
        //if (!userFriendlyAddress) return;
        addWallet({ address: userFriendlyAddress, type: "ton" });
    }, [userFriendlyAddress]);
    
    return (
        <div>
            <TonConnectButton className="ton-connect-button" />
        </div>
    );
}

export default WalletConnectComp