export class LanguageKeysFR {
    SpinTheWheel = "Tourner la Roue";
    PleaseWait = "Veuillez patienter";
    Hours = "Heures";
    Earn = "Jouer";
    Frens = "Amis";
    Wikz = "GetFi";
    Tasks = "Tâches";
    Airdrops = "Airdrops";
    //
    GiveItAWhirl = "Tente ta chance!";
    DonTForget = "N'oublie pas, tu peux revenir dans 8 heures";
    AnotherSpin = "pour un autre tour.";
    InviteFriends = "Invitez des amis";
    InviteAFriend = "Invitez un ami";
    GiveAwayWithoutLottery = "Cadeau sans tirage au sort";
    ReferralPrizePool = "Réserve des prix de parrainage";
    ForYouAndYourFriend = "Pour toi et ton ami";
    ListOYourFriends = "Liste de tes amis";
    Copied = "Copié!";
    //
    DailyTasks = "Tâches quotidiennes";
    Claim = "Réclamer";
    DailyLogIn = "Connexion quotidienne";
    CompleteYourDaily = "Accomplis tes tâches quotidiennes et gagne des récompenses!";
    Day = "Jour";
    EarnMoreCoins = "Gagne plus de pièces";
    Follow = "Suivre";
    Subscribe = "S'abonner";
    Join = "Rejoindre";
    //
    HowToEarn = "Comment gagner?";
    GetReadyForkWikzCoin = "Prépare-toi pour les pièces GET";
    WikZAppLaunched = "L'application GetFi est lancée!";
    NOTWIKZReferralPool = "Réserve de parrainage NOT et GET";
    WikZCoinsBigAirdrop = "Grand Airdrop des pièces GET";
    ExchangeListing = "Inscription en bourse";
    Soon = "Bientôt";
    //
    Vibrate = "Vibration";
    Language = "Langue";
    Dashboard = "Tableau de bord";
    Orders = "Commandes";
    Support = "Support";
    TelegramChannel = "Canal Telegram";
    //
    YourBalance = "Votre solde";
    Loading = "Chargement";
    Spinning = "En cours de rotation";
    Spin = "Tourner";
}

export type LanguageProps = keyof LanguageKeysFR;
export default LanguageKeysFR;

