export class LanguageKeysPT {
    SpinTheWheel = "Gire a Roda";
    PleaseWait = "Por favor, aguarde";
    Hours = "Horas";
    Earn = "Ganhar";
    Frens = "Amigos";
    Wikz = "GetFi";
    Tasks = "Tarefas";
    Airdrops = "Airdrops";
    //
    GiveItAWhirl = "Tente a sorte!";
    DonTForget = "Não se esqueça, volte em 8 horas";
    AnotherSpin = "para outra rodada.";
    InviteFriends = "Convide amigos";
    InviteAFriend = "Convide um amigo";
    GiveAwayWithoutLottery = "Presente sem sorteio";
    ReferralPrizePool = "Prêmio de referência";
    ForYouAndYourFriend = "Para você e seu amigo";
    ListOYourFriends = "Lista de seus amigos";
    Copied = "Copiado!";
    //
    DailyTasks = "Tarefas diárias";
    Claim = "Reivindicar";
    DailyLogIn = "Login diário";
    CompleteYourDaily = "Complete suas tarefas diárias e ganhe recompensas!";
    Day = "Dia";
    EarnMoreCoins = "Ganhe mais moedas";
    Follow = "Seguir";
    Subscribe = "Inscrever-se";
    Join = "Participar";
    //
    HowToEarn = "Como ganhar?";
    GetReadyForkWikzCoin = "Prepare-se para as moedas GET";
    WikZAppLaunched = "Aplicativo GetFi lançado!";
    NOTWIKZReferralPool = "Pool de referência NOT e GET";
    WikZCoinsBigAirdrop = "Grande Airdrop de moedas GET";
    ExchangeListing = "Listagem na bolsa";
    Soon = "Em breve";
    //
    Vibrate = "Vibrar";
    Language = "Idioma";
    Dashboard = "Painel";
    Orders = "Pedidos";
    Support = "Suporte";
    TelegramChannel = "Canal do Telegram";
    //
    YourBalance = "Seu saldo";
    Loading = "Carregando";
    Spinning = "Girando";
    Spin = "Girar";
}

export type LanguageProps = keyof LanguageKeysPT;
export default LanguageKeysPT;

