import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import WikzLogo from "../../../../assets/icons/getfi_icon_circle.png";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { wikzSelector } from "../../../../slices/wikz/slice";
import { useT } from "../../../../utils/locales/useLanguageState";

//@ts-ignore
const AnimatedNumber = ({ number }) => {
    const t = useT();
    const [data, setData] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API request with a delay
        const timeoutId = setTimeout(() => {
            setData(number);
            setIsLoading(false);
        }, 3000); // 3 seconds delay

        return () => clearTimeout(timeoutId); // Cleanup on unmount
    }, [number]);

    const formattedNumber = data !== null
        ? new Intl.NumberFormat('en-US', { style: 'decimal' }).format(data)
        : null;

    return (
        <div className="assets-list-number">
            {isLoading ? (
                <motion.div
                    key="loading"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ repeat: Infinity, repeatType: 'reverse', duration: 1 }}
                >
                    {t("Loading")}
                </motion.div>
            ) : (
                <motion.div
                    key="data"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                >
                    {formattedNumber}
                </motion.div>
            )}
        </div>
    );
};

const AssetListWikzItemComp = () => {
    const t = useT();
    const wikz = useSelector(wikzSelector);

    return (
        <div className="assets-list-item">
            <div className='asset-icon'>
                <Image src={WikzLogo} alt="WIKZ" />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div className="assets-list-title" style={{ width: '40%', textAlign: 'start' }}>
                    GET
                </div>
                <div className="assets-list-number" style={{ width: '55%', textAlign: 'center'}}>
                    <div style={{ opacity: 1, willChange: 'auto' }}>
                        <AnimatedNumber number={wikz?.data?.amount} />
                    </div>
                </div>
            </div>
            
            <button className="soon-button">{t("Soon")}</button>
        </div>
    );
};

export default AssetListWikzItemComp;
