export class LanguageKeysES {
    SpinTheWheel = "Gira la rueda";
    PleaseWait = "Por favor, espera";
    Hours = "Horas";
    Earn = "Gana";
    Frens = "Amigos";
    Wikz = "GetFi";
    Tasks = "Tareas";
    Airdrops = "Airdrop";
    //
    GiveItAWhirl = "¡Intenta tu suerte!";
    DonTForget = "No olvides, puedes volver en 8 horas";
    AnotherSpin = "para otro giro.";
    InviteFriends = "Invita a tus amigos";
    InviteAFriend = "Invita a un amigo";
    GiveAwayWithoutLottery = "Regalo sin sorteo";
    ReferralPrizePool = "Fondo de premios por referencia";
    ForYouAndYourFriend = "Para ti y tu amigo";
    ListOYourFriends = "Lista de tus amigos";
    Copied = "¡Copiado!";
    //
    DailyTasks = "Tareas Diarias";
    Claim = "Reclamar";
    DailyLogIn = "Inicio de sesión diario";
    CompleteYourDaily = "¡Completa tus tareas diarias y gana recompensas!";
    Day = "Día";
    EarnMoreCoins = "Gana más monedas";
    Follow = "Seguir";
    Subscribe = "Suscribirse";
    Join = "Unirse";
    //
    HowToEarn = "¿Cómo ganar?";
    GetReadyForkWikzCoin = "Prepárate para GET Coin";
    WikZAppLaunched = "¡La app de GetFi ha sido lanzada!";
    NOTWIKZReferralPool = "Fondo de Referencia NOT y GET";
    WikZCoinsBigAirdrop = "Gran Airdrop de GET Coins";
    ExchangeListing = "Listado en Exchange";
    Soon = "Pronto";
    //
    Vibrate = "Vibración";
    Language = "Idioma";
    Dashboard = "Panel de Control";
    Orders = "Pedidos";
    Support = "Soporte";
    TelegramChannel = "Canal de Telegram";
    //
    YourBalance = "Tu saldo";
    Loading = "Cargando";
    Spinning = "Girando";
    Spin = "Girar";
}

export type LanguageProps = keyof LanguageKeysES;
export default LanguageKeysES;

