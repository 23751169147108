export class LanguageKeysHI {
    SpinTheWheel = "पहिया घुमाएं";
    PleaseWait = "कृपया प्रतीक्षा करें";
    Hours = "घंटे";
    Earn = "कमाएं";
    Frens = "दोस्त";
    Wikz = "GetFi";
    Tasks = "कार्य";
    Airdrops = "एयरड्रॉप्स";
    //
    GiveItAWhirl = "अपना भाग्य आजमाएं!";
    DonTForget = "भूलें नहीं, 8 घंटे बाद फिर आ सकते हैं";
    AnotherSpin = "एक और घुमाने के लिए।";
    InviteFriends = "मित्रों को आमंत्रित करें";
    InviteAFriend = "एक मित्र को आमंत्रित करें";
    GiveAwayWithoutLottery = "लॉटरी के बिना उपहार";
    ReferralPrizePool = "रेफ़रल पुरस्कार पूल";
    ForYouAndYourFriend = "आपके और आपके मित्र के लिए";
    ListOYourFriends = "आपके मित्रों की सूची";
    Copied = "कॉपी हो गया!";
    //
    DailyTasks = "दैनिक कार्य";
    Claim = "दावा करें";
    DailyLogIn = "दैनिक लॉगिन";
    CompleteYourDaily = "अपना दैनिक कार्य पूरा करें और पुरस्कार अर्जित करें!";
    Day = "दिन";
    EarnMoreCoins = "और अधिक सिक्के कमाएं";
    Follow = "अनुसरण करें";
    Subscribe = "सदस्यता लें";
    Join = "शामिल हों";
    //
    HowToEarn = "कैसे कमाएं?";
    GetReadyForkWikzCoin = "GET सिक्के के लिए तैयार हो जाएं";
    WikZAppLaunched = "GetFi ऐप लॉन्च हो गया!";
    NOTWIKZReferralPool = "NOT और GET रेफरल पूल";
    WikZCoinsBigAirdrop = "GET सिक्कों का बड़ा एयरड्रॉप";
    ExchangeListing = "एक्सचेंज लिस्टिंग";
    Soon = "जल्द ही";
    //
    Vibrate = "वाइब्रेट करें";
    Language = "भाषा";
    Dashboard = "डैशबोर्ड";
    Orders = "आदेश";
    Support = "सहायता";
    TelegramChannel = "टेलीग्राम चैनल";
    //
    YourBalance = "आपका शेष";
    Loading = "लोड हो रहा है";
    Spinning = "घूम रहा है";
    Spin = "घुमाएं";
}

export type LanguageProps = keyof LanguageKeysHI;
export default LanguageKeysHI;

