import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { SpBtn, SpHlBtn } from '../../components/buttons/Store/StoreProductButton';
import StoreBreadcrumbComp from '../../components/breadcrumbs/store/StoreBreadcrumbComp';
import ProductsPage from './ProductsPage';
import StoreProductDetailPage from './ProductDetailPage';
import ProductPaymentPage from './ProductPaymentPage';
import ProductInvoicePage from './ProductInvoicePage';
import USDTImage from '../../assets/images/usdt.png';

import getStore from '../../store/get-store';
import axios from 'axios';
import { useAppSelector, useAppDispatch } from '../../store';
import { setCurrentShopView } from '../../slices/shopViewer/slice';

interface Category {
    id: number;
    title: string;
    sub_categories: Array<{
        id: number;
        title: string;
        image: {
            url: string;
        }
        shop_product: {
            id: number;
            name: string;
        } | null;
    }> | null;
    shop_product: {
        id: number;
        name: string;
    } | null;
    image: {
        url: string;
    };
    parent_categories?: number[];
}

const StorePage: React.FC = () => {
    const dispatch = useAppDispatch();

    const [categories, setCategories] = useState<Category[]>([]);
    const [mainCategories, setMainCategories] = useState<Category[]>([]);
    const [activeMainCategory, setActiveMainCategory] = useState<Category | null>(null);

    const [filteredSubCategories, setFilteredSubCategories] = useState<Category[]>([]);

    const [selectedProject, setSelectedProject] = useState<number | null>(null);

    const currentShopView = useAppSelector((state) => state.shopViewer.currentShopView);

    const [productId, setProductId] = useState<string>("");

    useEffect(() => {
        const jwt = getStore().getState()["auth/app"].data.jwt;

        const fetchCategories = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_ENDPOINT}/shop_categories`,
                    {
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    }
                );
                setCategories(response.data);
                const defaultCategory = response.data.find((cat: Category) => cat.title === "Store");
                if (defaultCategory) {
                    setActiveMainCategory(defaultCategory);
                    // Automatically filter subcategories when the default category is set
                    const subCategories = response.data.filter((cat: any) =>
                        cat.parent_categories && cat.parent_categories.includes(defaultCategory.id)
                    );
                    setFilteredSubCategories(subCategories);
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        handleMainCategoryClick(activeMainCategory || categories[0]);
    }, [activeMainCategory, categories]);

    const handleNavigate = (view: 'store' | 'products' | 'productDetails' | 'productPayment' | 'productInvoice') => {
        dispatch(setCurrentShopView(view));
    };

    useEffect(() => {
        // Filter main categories
        const mainCategories = categories.filter(
            (cat) => !cat.parent_categories || cat.parent_categories.length === 0
        );
        setMainCategories(mainCategories);
    }, [categories]);



    // Handle button click to set the active main category
    const handleMainCategoryClick = (category: Category) => {
        setActiveMainCategory(category);

        // Filter and set the subcategories based on the selected main category
        const subCategories = categories.filter(cat =>
            //@ts-ignore
            cat.parent_categories && cat.parent_categories.some(parent => parent.id === category.id)
        );

        setFilteredSubCategories(subCategories);
    };


    const renderSelected = () => {
        switch (currentShopView) {
            case 'store':
                return (
                    <>
                        <div>
                            <div className="row mb-3 mt-3"
                                style={{
                                    alignItems: "center",
                                    borderRadius: "10px",
                                    //margin: "-0.5rem 0", TODO: Buraya bakılacak
                                    marginLeft: "-0.4rem",
                                    marginRight: "-0.4rem",
                                }}>
                                {mainCategories.map((category) => (
                                    <div className="col-6 text-center" key={category.id}>
                                        <motion.button
                                            className='btn btn-primary '
                                            style={{
                                                backgroundColor: `${category.title === activeMainCategory?.title ? "transparent" : "transparent"}`,
                                                margin: "0.3rem 0",
                                                height: "45px",
                                                border: "none",
                                                borderRadius: "10px",
                                            }}
                                            onClick={() => handleMainCategoryClick(category)}
                                            whileHover={{ scale: 1 }}
                                            whileTap={{ scale: 0.9 }}
                                        >
                                            <span style={{
                                                fontSize: "1.2rem",
                                                color: "#504f4f",
                                                opacity: `${category.title === activeMainCategory?.title ? "1" : "0.5"}`,
                                                fontWeight: "700",
                                                textDecoration: "underline",
                                                textUnderlineOffset: "3px",
                                                textDecorationColor: `${category.title === activeMainCategory?.title ? "#5271ff" : "transparent"}`,
                                            }}>
                                                {category.title}
                                            </span>

                                        </motion.button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <AnimatePresence mode="wait">
                            {activeMainCategory && (
                                <motion.div
                                    key={activeMainCategory.id}
                                    initial={{ opacity: 0, x: -100 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: 100 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <div className="row mt-2">
                                        {activeMainCategory.sub_categories && activeMainCategory.sub_categories.length > 0 ? (
                                            filteredSubCategories.map((subCategory) => (
                                                <div key={subCategory.id} className="col-12">
                                                    <span
                                                        style={{
                                                            fontSize: "1rem",
                                                            fontWeight: "400",
                                                            color: "#595959",
                                                        }}>
                                                        {subCategory.title}
                                                    </span>
                                                    {subCategory.sub_categories && subCategory.sub_categories.length > 0 ? (
                                                        <div className="flex flex-wrap">
                                                            <div className="row">
                                                                {subCategory.sub_categories.slice(0, 2).map((subCategory2) => (
                                                                    <div key={subCategory2.id} className="col-6 mb-2 mt-1" style={{
                                                                        padding: "0 5px",
                                                                    }}>
                                                                        <button
                                                                            className="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                                                                            style={{
                                                                                height: "120px",
                                                                                backgroundColor: "#f4f3f8",
                                                                                border: "none",
                                                                                borderRadius: "12px"
                                                                            }}
                                                                            onClick={() => {
                                                                                setSelectedProject(subCategory2?.id || 0);
                                                                                handleNavigate('products');
                                                                            }}
                                                                        >
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                marginTop: "5px",
                                                                            }}>
                                                                                <img
                                                                                    //@ts-ignore
                                                                                    src={subCategory2?.image?.url ? subCategory2?.image?.url : USDTImage}
                                                                                    alt={subCategory2.title}
                                                                                    style={{
                                                                                        //@ts-ignore
                                                                                        width: `${subCategory.parent_categories[0].title == "Digital" ? '100px' : '60px'}`,
                                                                                        //@ts-ignore
                                                                                        height: `${subCategory.parent_categories[0].title == "Digital" ? '100px' : '60px'}`,
                                                                                        //@ts-ignore
                                                                                        marginBottom: `${subCategory.parent_categories[0].title == "Digital" ? '0' : '2px'}`,
                                                                                    }}
                                                                                />
                                                                                {
                                                                                    //@ts-ignore && TODO: Burası backend tarafında düzeltilecek
                                                                                    subCategory.parent_categories[0].title == "Digital" ? <></> : <span style={{
                                                                                        fontSize: "1.3rem",
                                                                                        fontWeight: "400",
                                                                                        color: "#7c7e8a",
                                                                                    }}>{subCategory2.title}</span>
                                                                                }


                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>


                                                            <div className="row">
                                                                {subCategory.sub_categories.slice(2).map((subCategory2) => (
                                                                    <div key={subCategory2.id} className="col-4 mb-2" style={{
                                                                        padding: "0 5px",
                                                                    }}> {/* Her buton için 4 sütun genişlik */}
                                                                        <button
                                                                            className="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                                                                            style={{
                                                                                backgroundColor: "#f4f3f8",
                                                                                border: "none",
                                                                                height: "80px",
                                                                                borderRadius: "12px",
                                                                            }}
                                                                            onClick={() => {
                                                                                setSelectedProject(subCategory2?.id || 0);
                                                                                handleNavigate('products');
                                                                            }}
                                                                        >
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                marginTop: "5px",
                                                                            }}>
                                                                                {subCategory2?.image && (
                                                                                    <img
                                                                                        //@ts-ignore
                                                                                        src={subCategory2?.image.url}
                                                                                        alt={subCategory2.title}
                                                                                        style={{
                                                                                            //@ts-ignore
                                                                                            width: `${subCategory.parent_categories[0].title == "Digital" ? '65px' : '35px'}`,
                                                                                            //@ts-ignore
                                                                                            height: `${subCategory.parent_categories[0].title == "Digital" ? '65px' : '35px'}`,
                                                                                            //@ts-ignore
                                                                                            marginBottom: `${subCategory.parent_categories[0].title == "Digital" ? '0' : '5px'}`,
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                {
                                                                                    //@ts-ignore && TODO: Burası backend tarafında düzeltilecek
                                                                                    subCategory.parent_categories[0].title == "Digital" ? <></> : <span style={{
                                                                                        fontSize: "0.75rem",
                                                                                        fontWeight: "400",
                                                                                        color: "#7c7e8a",
                                                                                    }}>{subCategory2.title}</span>
                                                                                }

                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>


                                                        </div>
                                                    ) : (
                                                        <p>No sub-subcategories available.</p>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <p>No subcategories available.</p>
                                        )}
                                    </div>

                                </motion.div>
                            )}
                        </AnimatePresence>
                    </>
                );
            case 'products':
                return (
                    <AnimatePresence mode="wait">
                        <motion.div
                            key="products"
                            initial={{ opacity: 0, y: 100 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -100 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div className="row mb-3">
                                <div className="col text-center">
                                    <ProductsPage
                                        id={selectedProject || 0}
                                        onCartClick={(productId) => {
                                            setProductId(productId);
                                            handleNavigate('productDetails');
                                        }}
                                    />
                                </div>
                            </div>
                        </motion.div>
                    </AnimatePresence>
                );
            case 'productDetails':
                return (
                    <AnimatePresence mode="wait">
                        <motion.div
                            key="productDetails"
                            initial={{ opacity: 0, y: 100 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -100 }}
                            transition={{ duration: 0.5 }}
                        >
                            <StoreProductDetailPage
                                productId={productId}
                                onBuyNowClick={() => {
                                    handleNavigate('productPayment');
                                }}
                            />
                        </motion.div>
                    </AnimatePresence>
                );
            case 'productPayment':
                return (
                    <AnimatePresence mode="wait">
                        <motion.div
                            key="productPayment"
                            initial={{ opacity: 0, y: 100 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -100 }}
                            transition={{ duration: 0.5 }}
                        >
                            <ProductPaymentPage />
                        </motion.div>
                    </AnimatePresence>
                );
            case 'productInvoice':
                return (
                    <AnimatePresence mode="wait">
                        <motion.div
                            key="productInvoice"
                            initial={{ opacity: 0, y: 100 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -100 }}
                            transition={{ duration: 0.5 }}
                        >
                            <ProductInvoicePage />
                        </motion.div>
                    </AnimatePresence>
                );
            default:
                return <div>Unknown view</div>;
        }
    };

    return (
        <div style={{
            marginBottom: "40px"
        }}>
            <StoreBreadcrumbComp currentView={currentShopView} onNavigate={handleNavigate} />
            {renderSelected()}
        </div>
    );
};

export default StorePage;
