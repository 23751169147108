// @ts-nocheck
import OrderQuantity from "../../helpers/product/orderQuantity";
import "./style.css";
import { GrPrevious, GrNext, GrShareOption } from "react-icons/gr";
import { useFetchShopProductQuery } from "../../slices/shopProduct/api";
import { useEffect, useState } from "react";

import { useAppDispatch } from "../../store";
import { addProductToBasket } from "../../slices/shopBasket/slice";
import { purchaseProduct } from "../../slices/shopPurchase/slice";

//Icons
import FindMySizeIcon from "../../assets/icons/find-my-size-icon.png";

//Components
import FindMySizeModalComp from "../../components/modals/FindMySize.modal.comp";
import { useFetchShopProductDetailQuery } from "../../slices/shopProductDetail/api";
import StoreProductDetailCard from "../../components/cards/store/store.product.detail.card";

interface IStoreProductDetail {
  productId: string;
  onBuyNowClick: () => void;
}

const StoreProductDetailPage = (props: IStoreProductDetail) => {
  const dispatch = useAppDispatch();

  const { data, refetch, isLoading } = useFetchShopProductQuery(
    { id: props.productId },
    { skip: false }
  );

  const [selectedVariant, setSelectedVariant] = useState<any>(null);
  const [variant, setVariant] = useState<any>(null);
  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1);
  const [colorWarning, setColorWarning] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState(0);

  const [findMySizeModal, setFindMySizeModal] = useState(false);

  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [availableColors, setAvailableColors] = useState<string[]>([]);
  const [availablePics, setAvailablePics] = useState<string[]>([]);
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const [colorsLoading, setColorsLoading] = useState(true);

  // Modal açma/kapatma fonksiyonları
  const handleOpenModal = () => setFindMySizeModal(true);
  const handleCloseModal = () => setFindMySizeModal(false);

  //@ts-ignore
  //const { data: productsVariant, isLoading: isLoadingProductsVariant } = useFetchProductsVariantQuery({ id: data?.sync_variants[0].variant_id }, { skip: false });

  const fetchVariantColors = async () => {
    setColorsLoading(true); // Renkler yüklenirken loading göster
    if (variant < 1) {
      const colorPromises = variant?.map(async (variant: any) => {
        // Variant ID'ye göre productsVariant verisini çek
        const productsVariantResponse = variant;
        const productsVariantData = variant;
        // Variant ID ve color_code bilgisini geri döndür
        return { colorName: variant.color };
      });

      const colors = await Promise.all(colorPromises)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return [];
        });

      setColorsLoading(false);

      // Duplicate colors will be filtered by colorName and colorCode
      const uniqueColors = colors?.filter(
        (color: any, index: any, self: any) =>
          index ===
          self.findIndex(
            (c: any) =>
              c.colorName === color.colorName && c.colorCode === color.colorCode
          )
      );

      //@ts-ignore
      setAvailableColors(uniqueColors);
    }
    //@ts-ignore
    else if (data?.sync_variants) {
      //@ts-ignore

      const colorPromises = Array.from(
        new Set(data?.sync_variants?.map((variant: any) => variant.color))
      ).map(async (colorName) => {
        return { colorName };
      });

      // Tüm promise'lerin çözülmesini bekleyelim
      const colors = await Promise.all(colorPromises)
        .then((res) => {
          setColorsLoading(false);
          console.log(">>>res", res);
          setAvailableColors(res);
          return res;
        })
        .catch((err) => {
          return [];
        });

      // Duplicate colors will be filtered by colorName and colorCode

      //@ts-ignore
    }
  };

  async function getAllFiles() {
    const filePromises = data?.sync_variants
    ?.map((variant) =>
      variant.files.map(async (file) => {
        // Add color field to each file object
        return { ...file, color: variant.color };
      })
    )
    .flat();
  
    // Resolve all promises
    const resolvedFiles = await Promise.all(filePromises);
    
    // Use a Map to store files by unique 'id' to ensure uniqueness
    const uniqueFiles = Array.from(
      new Map(resolvedFiles.map((file) => [file.id, file])).values()
    );
    
    console.log(">>>>>", uniqueFiles);
    setAvailablePics(uniqueFiles);
  }

  useEffect(() => {
    fetchVariantColors();
    getAllFiles();
  }, [variant]);

  useEffect(() => {
    //@ts-ignore
    if (data?.sync_variants) {
      //@ts-ignore
      console.log(data?.sync_variants);
      //@ts-ignore
      const initialSize = data.sync_variants[0].size;
      setSelectedSize(initialSize);
      fetchVariantColors();
      //@ts-ignore
      const initialColorVariants = data.sync_variants.filter(
        (variant: any) => variant.size === initialSize
      );

      if (initialColorVariants.length > 0) {
        const initialColor = initialColorVariants[0].color;
        setSelectedColor(initialColor);
        setVariant(initialColorVariants[0]);

        // İlk varyantın resmini al
        const initialPreviewUrl =
          initialColorVariants[0]?.files?.[1]?.preview_url;
        setActiveIndex(0); // İlk resim aktif
      }
    }
  }, [data]);

  useEffect(() => {
    setAvailableColors([]);
    // Herhangi bir boyut seçildiğinde ilgili renklere göre güncellemeler yap
    //@ts-ignore
    if (selectedSize && data?.sync_variants) {
      //@ts-ignore
      const filteredVariants = data.sync_variants.filter(
        (variant: any) => variant.size === selectedSize
      );

      setVariant(filteredVariants);
    }
  }, [selectedSize, data]);

  const handleAddProductToBasket = () => {
    const thumbnail_url = selectedVariant.files[1]?.preview_url;
    //@ts-ignore
    if (data?.sync_product && data?.sync_variants) {
      // Select a variant, you might want to use user input here
      //@ts-ignore
      const selectedVariant = data.sync_variants[0]; // Example: select the first variant

      const product = {
        //@ts-ignore
        ...data.sync_product, // Copy sync_product properties
        //@ts-ignore
        retail_price: selectedVariant.retail_price, // Add retail_price from the selected variant
        //@ts-ignore
        variant_id: selectedVariant.variant_id, // Add variant-specific information
        size: selectedSize,
        color: selectedColor,
        //@ts-ignore
        thumbnail_url: thumbnail_url, // Example: use variant image
        productQuantity: selectedQuantity, // Pass the selected quantity here
        //@ts-ignore
        files: selectedVariant.files,
      };
      // Now pass the product with complete details
      dispatch(addProductToBasket(product));
      setColorWarning(false); // Uyarıyı kaldır
    }
  };

  const handleBuyNow = () => {
    const thumbnail_url = selectedVariant.files[1]?.preview_url;
    //@ts-ignore
    if (data?.sync_product && selectedVariant) {
      const product = {
        //@ts-ignore
        ...data.sync_product,
        retail_price: selectedVariant.retail_price,
        variant_id: selectedVariant.variant_id,
        size: selectedSize,
        color: selectedColor,
        thumbnail_url: thumbnail_url,
        productQuantity: selectedQuantity,
        files: selectedVariant.files,
      };

      dispatch(purchaseProduct(product));

      props.onBuyNowClick();
    }
  };

  useEffect(() => {
    //@ts-ignore
    if (selectedColor && selectedSize && data?.sync_variants) {
      //@ts-ignore
      const variant = data.sync_variants.find(
        (v: any) => v.color === selectedColor && v.size === selectedSize
      );

      if (variant) {
        setSelectedVariant(variant);
        setActiveIndex(0);
      } else {
        setSelectedVariant(null);
      }
    }
  }, [selectedColor, selectedSize, data]);

  const handleSizeChange = (size: string) => {
    setSelectedSize(size);
    setColorWarning(false); // Reset color warning

    // Filter variants based on the selected size
    //@ts-ignore
    const filteredVariants =
      data?.sync_variants.filter((variant: any) => variant.size === size) || [];

    // Update available colors based on filtered variants
    const colors = filteredVariants?.map((variant: any) => variant.color);

    setAvailableColors(colors);

    // If colors are available, select the first one
    if (colors.length > 0) {
      setSelectedColor(colors[0]);

      // Find and set the selected variant based on new size and first color
      const firstVariant = filteredVariants.find(
        (variant: any) => variant.color === colors[0]
      );
      setVariant(firstVariant);
    } else {
      // Reset if no colors are available
      setVariant(null);
      setSelectedColor(null);
    }
  };

  const handleColorChange = (color: string) => {
    setSelectedColor(color);
    setColorWarning(false);
  
    // Find all carousel items with the matching color
    const carouselItems = document.querySelectorAll('.carousel-item');
    const matchingItems = Array.from(carouselItems).filter((item) => 
      item.getAttribute('data-color') === color
    );
  
    if (matchingItems.length > 0) {
      // Remove the 'active' class from the current active item
      const currentActive = document.querySelector('.carousel-item.active');
      if (currentActive) {
        currentActive.classList.remove('active');
      }
  
      // Set the last matching item as active
      const lastMatchingItem = matchingItems[matchingItems.length - 1];
      lastMatchingItem.classList.add('active');
    }
  };
  
  

  useEffect(() => {
    if (availableColors.length > 0) {
      setCurrentColorIndex(0); // Reset index if colors are available
    } else {
      setCurrentColorIndex(-1); // Set to an invalid index if no colors are available
    }
  }, [availableColors]);

  // Go to the next color
  const nextColor = () => {
    const carouselItems = document.querySelectorAll('.carousel-item');
  let activeIndex = Array.from(carouselItems).findIndex(item => 
    item.classList.contains('active')
  );
  carouselItems[activeIndex].classList.remove('active');
  const nextIndex = (activeIndex + 1) % carouselItems.length;

  // Add 'active' class to the next item
  carouselItems[nextIndex].classList.add('active');
  const nextColor = carouselItems[nextIndex].getAttribute('data-color');
  setSelectedColor(nextColor);
  };

  const prevColor = () => {
    const carouselItems = document.querySelectorAll('.carousel-item');
  let activeIndex = Array.from(carouselItems).findIndex(item => 
    item.classList.contains('active')
  );
  carouselItems[activeIndex].classList.remove('active');
  const prevIndex = (activeIndex - 1 + carouselItems.length) % carouselItems.length;

  // Add 'active' class to the previous item
  carouselItems[prevIndex].classList.add('active');
  const prevColor = carouselItems[prevIndex].getAttribute('data-color');
  setSelectedColor(prevColor);
  };

  // Handle quantity change
  const handleQuantityChange = (quantity: number) => {
    setSelectedQuantity(quantity);
  };

  if (isLoading || !selectedVariant) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mb-5">
      <div
        className="text-white py-4"
        style={{
          backgroundColor: "#dcdbdb",
          borderRadius: "10px",
          //padding: "1rem",
          width: "100%",
          height: "325px",
        }}
      >
        <div className="flex">
          <div className="row">
            <div className="col-md-6 col-lg-12">
              <div
                id="productCarousel"
                className="carousel slide"
                data-ride="carousel"
              >
                <div className="carousel-inner">
                  {console.log('selectedVar', selectedVariant)}
                  {availablePics?.map((file, index) => {
                    return(
                    <div
                      key={file.id}
                      data-color={file.color}
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                    >
                      <img
                        className="d-block w-100"
                        src={
                          file.preview_url || "https://via.placeholder.com/500"
                        }
                        alt={`Slide ${index + 1}`}
                        style={{
                          height: "270px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  )})}
                </div>
                {colorsLoading ? null : (
                  <>
                    <a
                      className="carousel-control-prev"
                      role="button"
                      onClick={prevColor}
                      style={{ cursor: "pointer" }}
                    >
                      <GrPrevious size={30} color="#7c7e8a" />
                    </a>

                    <a
                      className="carousel-control-next"
                      role="button"
                      onClick={nextColor}
                      style={{ cursor: "pointer" }}
                    >
                      <GrNext size={30} color="#7c7e8a" />
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Product Details Section */}
      <div className="flex" style={{ margin: "0 1rem" }}>
        <span
          className="row"
          style={{
            color: "#7c7e8a",
            fontWeight: "700",
            fontSize: "1.3rem",
            marginTop: "1rem",
          }}
        >
          {
            //@ts-ignore
            data?.sync_product.name
          }
        </span>
        <div className="row mb-2 mt-2 align-items-center">
          {/* Fiyat kısmı */}
          <div className="d-flex align-items-center w-75 p-0">
            <span
              style={{
                color: "#7c7e8a",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                fontFamily: "Impact",
              }}
            >
              <strong style={{ fontSize: "2rem", fontWeight: "700" }}>
                <span style={{ marginRight: "0.3rem" }}>$</span>
                <span>
                  {
                    //@ts-ignore
                    selectedVariant?.retail_price
                      .split(".")
                      ?.map((part, index) => (
                        <span key={index} style={{ textDecoration: "none" }}>
                          {index === 0 ? (
                            <span style={{ textDecoration: "underline" }}>
                              {part}
                            </span> // Noktadan önceki kısım altı çizili
                          ) : (
                            <span
                              style={{ fontSize: "2rem", marginBottom: "2rem" }}
                            >
                              <sup>.{part}</sup>{" "}
                              {/* Noktadan sonraki kısmı üstel olarak göster */}
                            </span>
                          )}
                        </span>
                      ))
                  }
                </span>
              </strong>
            </span>
          </div>

          {/* Paylaş Butonu */}
          {/*  <div className="d-flex justify-content-end w-25 p-0">
            <button
              className="btn btn-share border-none d-flex align-items-center"
              style={{
                padding: "0 10px",
                width: "100px",
                height: "30px",
                fontSize: "1rem",
                fontWeight: "300",
              }}
            >
              <GrShareOption style={{
                padding: "0 1px",
              }} /> Share
            </button>
          </div> */}
        </div>

        {/* Size Section */}
        <div className="flex row my-3">
          <div className="col-md-12 p-0">
            <div className="d-flex item align-items-center">
              <span
                style={{
                  color: "#7c7e8a",
                  fontWeight: "300",
                  fontSize: "1rem",
                  marginRight: "1rem",
                }}
              >
                Size
              </span>
              <select
                className="form-select custom-select"
                onChange={(e) => handleSizeChange(e.target.value)}
                value={selectedSize || ""}
                style={{
                  color: "#7c7e8a",
                  fontWeight: "300",
                  fontSize: "1rem",
                  marginRight: "1rem",
                }}
              >
                {
                  //@ts-ignore
                  data.sync_variants
                    ?.map((variant: any) => variant.size)
                    .filter(
                      (size: string, index: number, self: string[]) =>
                        self.indexOf(size) === index
                    ) // Remove duplicate sizes
                    ?.map((size: string) => (
                      <option
                        key={size}
                        value={size}
                        style={{
                          color: "#7c7e8a",
                          fontWeight: "300",
                          fontSize: "1rem",
                        }}
                      >
                        {size}
                      </option>
                    ))
                }
              </select>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={handleOpenModal} // Tıklandığında modal açılacak
              >
                <span
                  style={{
                    color: "#7c7e8a",
                    fontWeight: "300",
                    fontSize: "0.8rem",
                    cursor: "pointer",
                  }}
                >
                  Find my size
                </span>
                <img
                  src={FindMySizeIcon}
                  style={{
                    width: "15px",
                    height: "15px",
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                    objectFit: "contain",
                    backgroundPosition: "contain",
                  }}
                ></img>
              </div>
            </div>
          </div>
        </div>

        <FindMySizeModalComp
          show={findMySizeModal}
          handleClose={handleCloseModal}
          sizeInfo={selectedSize} // Modal'a veri gönderiyoruz
        />

        {/* Color Section */}
        <div className="row my-3">
          <div className="col-md-12 p-0">
            <div className="d-flex">
              <span
                style={{
                  color: "#7c7e8a",
                  fontWeight: "300",
                  fontSize: "1rem",
                }}
              >
                Color
              </span>
              {colorsLoading ? (
                <div style={{ paddingLeft: "10px" }}>Loading...</div>
              ) : (
                <div className="d-flex item" style={{ overflowX: "auto" }}>
                  <div
                    style={{
                      display: "flex",
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                      padding: "0 1rem",
                      minWidth: "800px",
                    }}
                  >
                    {availableColors.map((color) => (
                      <div
                        key={color.colorName}
                        //@ts-ignore
                        onClick={() => handleColorChange(color.colorName)}
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          //@ts-ignore
                          backgroundColor: color.colorName,
                          cursor: "pointer",
                          marginRight: "5px",
                          //@ts-ignore
                          border:
                            selectedColor === 'White'
                              ? "2px solid #7c7e8a"
                              : "none", // Seçilen rengi vurgulamak için
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Quantity and Action Buttons Section */}
        <div className="row my-3 justify-content-center align-items-center">
          <span
            className="p-0"
            style={{
              color: "##7c7e8a",
              fontWeight: "300",
              fontSize: "1rem",
            }}
          >
            Quantity
          </span>
          <div className="col-4 align-items-center p-0 m-0">
            <OrderQuantity
              initialQuantity={1}
              onQuantityChange={handleQuantityChange}
            />
          </div>
          <div
            className="col-8 justify-content-between d-flex"
            style={{
              padding: "0",
              margin: "0",
              color: "#ffffff",
              fontWeight: "300",
              fontSize: "1rem",
            }}
          >
            <div className="d-flex gap-1 w-100 p-0">
              <button
                className={`btn btn-primary ${colorsLoading ? "disabled" : ""}`}
                style={{
                  marginLeft: "0.4rem",
                  color: "#ffffff",
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  padding: "0 3px",
                  backgroundColor: `${colorsLoading ? "gray" : "#1e89e4"}`,
                  height: "40px",
                  border: "none",
                }}
                onClick={() => handleAddProductToBasket()}
                disabled={colorsLoading}
              >
                Add To Cart
              </button>
              <button
                className={`btn btn-primary ${colorsLoading ? "disabled" : ""}`}
                style={{
                  backgroundColor: `${colorsLoading ? "gray" : "#33b83b"}`,
                  color: "#ffffff",
                  fontWeight: "600",
                  fontSize: "0.9rem",
                  padding: "0 3px",
                  height: "40px",
                  border: "none",
                }}
                onClick={handleBuyNow}
                disabled={colorsLoading}
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <StoreProductDetailCard productId={props.productId} />
    </div>
  );
};

export default StoreProductDetailPage;
