export class LanguageKeysTL {
    SpinTheWheel = "Paikutin ang Gulong";
    PleaseWait = "Pakiusap, maghintay";
    Hours = "Oras";
    Earn = "Kumita";
    Frens = "Mga Kaibigan";
    Wikz = "GetFi";
    Tasks = "Mga Gawain";
    Airdrops = "Airdrops";
    //
    GiveItAWhirl = "Subukan ang iyong swerte!";
    DonTForget = "Huwag kalimutan, maaari kang bumalik sa loob ng 8 oras";
    AnotherSpin = "para sa isa pang pag-ikot.";
    InviteFriends = "Imbitahan ang mga Kaibigan";
    InviteAFriend = "Imbitahan ang isang Kaibigan";
    GiveAwayWithoutLottery = "Regalo na walang raffle";
    ReferralPrizePool = "Referral Prize Pool";
    ForYouAndYourFriend = "Para sa iyo at sa iyong kaibigan";
    ListOYourFriends = "Listahan ng iyong mga kaibigan";
    Copied = "Nakopya!";
    //
    DailyTasks = "Araw-araw na Gawain";
    Claim = "Kunin";
    DailyLogIn = "Araw-araw na Pag-login";
    CompleteYourDaily = "Kumpletuhin ang iyong mga gawain araw-araw at makuha ang mga gantimpala!";
    Day = "Araw";
    EarnMoreCoins = "Kumita ng Higit pang Barya";
    Follow = "Sundan";
    Subscribe = "Mag-subscribe";
    Join = "Sumali";
    //
    HowToEarn = "Paano Kumita?";
    GetReadyForkWikzCoin = "Maging handa para sa GET Coins";
    WikZAppLaunched = "Inilunsad na ang GetFi App!";
    NOTWIKZReferralPool = "NOT at GET Referral Pool";
    WikZCoinsBigAirdrop = "Malaking Airdrop ng GET Coins";
    ExchangeListing = "Paglista sa Palitan";
    Soon = "Malapit na";
    //
    Vibrate = "Mag-vibrate";
    Language = "Wika";
    Dashboard = "Dashboard";
    Orders = "Mga Order";
    Support = "Suporta";
    TelegramChannel = "Telegram Channel";
    //
    YourBalance = "Ang iyong Balanse";
    Loading = "Naglo-load";
    Spinning = "Umiikot";
    Spin = "Paikutin";
}

export type LanguageProps = keyof LanguageKeysTL;
export default LanguageKeysTL;

