export class LanguageKeysTH {
    SpinTheWheel = "หมุนวงล้อ";
    PleaseWait = "กรุณารอสักครู่";
    Hours = "ชั่วโมง";
    Earn = "รับรายได้";
    Frens = "เพื่อน";
    Wikz = "GetFi";
    Tasks = "งาน";
    Airdrops = "แอร์ดรอป";
    //
    GiveItAWhirl = "ลองเสี่ยงโชคของคุณ!";
    DonTForget = "อย่าลืมกลับมาในอีก 8 ชั่วโมง";
    AnotherSpin = "สำหรับการหมุนครั้งถัดไป";
    InviteFriends = "เชิญเพื่อน";
    InviteAFriend = "เชิญเพื่อนหนึ่งคน";
    GiveAwayWithoutLottery = "ของขวัญโดยไม่ต้องจับสลาก";
    ReferralPrizePool = "รางวัลแนะนำ";
    ForYouAndYourFriend = "สำหรับคุณและเพื่อนของคุณ";
    ListOYourFriends = "รายชื่อเพื่อนของคุณ";
    Copied = "คัดลอกแล้ว!";
    //
    DailyTasks = "งานประจำวัน";
    Claim = "รับสิทธิ์";
    DailyLogIn = "เข้าสู่ระบบประจำวัน";
    CompleteYourDaily = "ทำภารกิจประจำวันให้สำเร็จและรับรางวัล!";
    Day = "วัน";
    EarnMoreCoins = "รับเหรียญเพิ่ม";
    Follow = "ติดตาม";
    Subscribe = "สมัครสมาชิก";
    Join = "เข้าร่วม";
    //
    HowToEarn = "วิธีรับรายได้?";
    GetReadyForkWikzCoin = "เตรียมพร้อมสำหรับเหรียญ GET";
    WikZAppLaunched = "เปิดตัวแอป GetFi แล้ว!";
    NOTWIKZReferralPool = "พูลแนะนำ NOT และ GET";
    WikZCoinsBigAirdrop = "แอร์ดรอปเหรียญ GET ครั้งใหญ่";
    ExchangeListing = "รายชื่อในตลาดแลกเปลี่ยน";
    Soon = "เร็วๆ นี้";
    //
    Vibrate = "การสั่น";
    Language = "ภาษา";
    Dashboard = "แดชบอร์ด";
    Orders = "คำสั่งซื้อ";
    Support = "การสนับสนุน";
    TelegramChannel = "ช่อง Telegram";
    //
    YourBalance = "ยอดเงินของคุณ";
    Loading = "กำลังโหลด";
    Spinning = "กำลังหมุน";
    Spin = "หมุน";
}

export type LanguageProps = keyof LanguageKeysTH;
export default LanguageKeysTH;

