export class LanguageKeysID {
    SpinTheWheel = "Putar Roda";
    PleaseWait = "Harap Tunggu";
    Hours = "Jam";
    Earn = "Dapatkan";
    Frens = "Teman";
    Wikz = "GetFi";
    Tasks = "Tugas";
    Airdrops = "Airdrop";
    //
    GiveItAWhirl = "Coba keberuntunganmu!";
    DonTForget = "Jangan lupa, kamu bisa kembali dalam 8 jam";
    AnotherSpin = "untuk putaran lainnya.";
    InviteFriends = "Undang Teman";
    InviteAFriend = "Undang seorang teman";
    GiveAwayWithoutLottery = "Hadiah tanpa undian";
    ReferralPrizePool = "Kolam Hadiah Referral";
    ForYouAndYourFriend = "Untuk kamu dan temanmu";
    ListOYourFriends = "Daftar teman-temanmu";
    Copied = "Disalin!";
    //
    DailyTasks = "Tugas Harian";
    Claim = "Klaim";
    DailyLogIn = "Masuk Harian";
    CompleteYourDaily = "Selesaikan tugas harianmu dan dapatkan hadiah!";
    Day = "Hari";
    EarnMoreCoins = "Dapatkan Lebih Banyak Koin";
    Follow = "Ikuti";
    Subscribe = "Langganan";
    Join = "Bergabung";
    //
    HowToEarn = "Bagaimana Mendapatkan?";
    GetReadyForkWikzCoin = "Bersiaplah untuk koin GET";
    WikZAppLaunched = "Aplikasi GetFi Diluncurkan!";
    NOTWIKZReferralPool = "Kolam Referral NOT dan GET";
    WikZCoinsBigAirdrop = "Airdrop Besar Koin GET";
    ExchangeListing = "Daftar Pertukaran";
    Soon = "Segera";
    //
    Vibrate = "Getar";
    Language = "Bahasa";
    Dashboard = "Dasbor";
    Orders = "Pesanan";
    Support = "Dukungan";
    TelegramChannel = "Saluran Telegram";
    //
    YourBalance = "Saldo Anda";
    Loading = "Memuat";
    Spinning = "Berputar";
    Spin = "Putar";
}

export type LanguageProps = keyof LanguageKeysID;
export default LanguageKeysID;

