import React, {useCallback, useMemo, createContext, SetStateAction} from "react";

import {LanguageProps} from "../../constants/languages/tr";
import * as LanguageKeys from '../../constants/languages';

import { getStorageData } from "../localState/usePersistedState";
 
const englishKeys = new LanguageKeys.LanguageKeysEN();
const germanKeys = new LanguageKeys.LanguageKeysDE();
const spanishKeys = new LanguageKeys.LanguageKeysES();
const frenchKeys = new LanguageKeys.LanguageKeysFR();
const hindiKeys = new LanguageKeys.LanguageKeysHI();
const indonesianKeys = new LanguageKeys.LanguageKeysID();
const portugueseKeys = new LanguageKeys.LanguageKeysPT();
const russianKeys = new LanguageKeys.LanguageKeysRU();
const thaiKeys = new LanguageKeys.LanguageKeysTH();
const tagalogKeys = new LanguageKeys.LanguageKeysTL();
const turkishKeys = new LanguageKeys.LanguageKeysTR();
const uzbekKeys = new LanguageKeys.LanguageKeysUZ();
const vietnameseKeys = new LanguageKeys.LanguageKeysVI();
type LanguageCode = "en" | "ar" | "de" | "es" | "fr" | "hi" | "id" | "pt" | "ru" | "th" | "tl" | "tr" | "uz" | "vi";

type State = { language: string; keys: any};

const LanguageStateContext = createContext<State | undefined>(undefined);
const LanguageUpdaterContext = createContext<React.Dispatch<SetStateAction<LanguageCode>> | undefined>(undefined);

type LanguageProviderProps = {
    children?: React.ReactNode;
};

const LanguageProvider: React.FC<LanguageProviderProps> = ({children}) => {
    
    const [language, setLanguage] = React.useState<LanguageCode>( "tr");
    let languageData;

    switch (language) {
        case "en":
            languageData = englishKeys;
            break;
        case "de":
            languageData = germanKeys;
            break;
        case "es":
            languageData = spanishKeys;
            break;
        case "fr":
            languageData = frenchKeys;
            break;
        case "hi":
            languageData = hindiKeys;
            break;
        case "id":
            languageData = indonesianKeys;
            break;
        case "pt":
            languageData = portugueseKeys;
            break;
        case "ru":
            languageData = russianKeys;
            break;
        case "th":
            languageData = thaiKeys;
            break;
        case "tl":
            languageData = tagalogKeys;
            break;
        case "tr":
            languageData = turkishKeys;
            break;
        case "uz":
            languageData = uzbekKeys;
            break;
        case "vi":
            languageData = vietnameseKeys;
            break;
        default:
            languageData = englishKeys; // Default fallback
            break;
    }
    

    const value = useMemo(() => {
        return {
            language,
            keys: languageData
        };
    }, [language]);

    return (
        <LanguageStateContext.Provider value={value}>
            <LanguageUpdaterContext.Provider value={setLanguage}>
                {children}
            </LanguageUpdaterContext.Provider>
        </LanguageStateContext.Provider>
    );
};

function useLanguageState() {
    const languageState = React.useContext(LanguageStateContext);

    if (languageState === undefined) {
        throw new Error("useLanguageState must be use within a LangProvider");
    }

    return languageState;
}

function useT() {
    const languageState = useLanguageState();
    const t = useCallback((id: LanguageProps, data?: any): string => {
            const textItem: any = (languageState.keys as any)[id];
            const str: string = textItem || id;

            return textItem instanceof Function ? textItem(data) : str;
        },
        [languageState]);
    return t;
}

function useLanguage() {
    getStorageData(`languageState`, false)
    const languageState = useLanguageState();
    // setStorageData(`languageState`, {
    //     app:languageState.language
    // }, false)
    return languageState.language;
}

function useSetLanguage() {
    const setLanguage = React.useContext(LanguageUpdaterContext);
    if (setLanguage === undefined) {
        throw new Error("useSetLang must be used within a LangProvider");

    }
    return setLanguage;
}

type Props = { id: LanguageProps; data?: any };

const T = React.memo(({ id, data }: Props) => {
    const t = useT();
    return <>{t(id, data)}</>;
});

export {LanguageProvider, useSetLanguage, useT, useLanguage, T};
