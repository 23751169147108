export class LanguageKeysUZ {
    SpinTheWheel = "G‘ildirakni aylantiring";
    PleaseWait = "Iltimos, kuting";
    Hours = "Soat";
    Earn = "Daromad olish";
    Frens = "Do‘stlar";
    Wikz = "GetFi";
    Tasks = "Vazifalar";
    Airdrops = "Airdroplar";
    //
    GiveItAWhirl = "Omadingizni sinang!";
    DonTForget = "Unutmang, 8 soatdan keyin qaytib kelishingiz mumkin";
    AnotherSpin = "yana aylantirish uchun.";
    InviteFriends = "Do‘stlarni taklif qilish";
    InviteAFriend = "Bir do‘stni taklif qilish";
    GiveAwayWithoutLottery = "Loteriyasiz sovg‘a";
    ReferralPrizePool = "Referal mukofot jamg‘armasi";
    ForYouAndYourFriend = "Siz va do‘stingiz uchun";
    ListOYourFriends = "Do‘stlaringiz ro‘yxati";
    Copied = "Nusxa olindi!";
    //
    DailyTasks = "Kunlik vazifalar";
    Claim = "Talab qilish";
    DailyLogIn = "Kunlik kirish";
    CompleteYourDaily = "Kunlik vazifalarni bajaring va mukofotlaringizni oling!";
    Day = "Kun";
    EarnMoreCoins = "Ko‘proq tanga oling";
    Follow = "Kuzatib boring";
    Subscribe = "Obuna bo‘ling";
    Join = "Qo‘shiling";
    //
    HowToEarn = "Qanday daromad olish mumkin?";
    GetReadyForkWikzCoin = "GET tangalari uchun tayyor bo‘ling";
    WikZAppLaunched = "GetFi ilovasi ishga tushirildi!";
    NOTWIKZReferralPool = "NOT va GET referal jamg‘armasi";
    WikZCoinsBigAirdrop = "GET tanga katta airdropi";
    ExchangeListing = "Birja ro‘yxati";
    Soon = "Tez orada";
    //
    Vibrate = "Vibratsiya";
    Language = "Til";
    Dashboard = "Boshqaruv paneli";
    Orders = "Buyurtmalar";
    Support = "Qo‘llab-quvvatlash";
    TelegramChannel = "Telegram kanali";
    //
    YourBalance = "Hisobingiz";
    Loading = "Yuklanmoqda";
    Spinning = "Aylanmoqda";
    Spin = "Aylantirish";
}

export type LanguageProps = keyof LanguageKeysUZ;
export default LanguageKeysUZ;

