export class LanguageKeysVI {
    SpinTheWheel = "Quay Vòng";
    PleaseWait = "Vui lòng chờ";
    Hours = "Giờ";
    Earn = "Kiếm";
    Frens = "Bạn bè";
    Wikz = "GetFi";
    Tasks = "Nhiệm vụ";
    Airdrops = "Airdrops";
    //
    GiveItAWhirl = "Thử vận may của bạn!";
    DonTForget = "Đừng quên, bạn có thể quay lại sau 8 giờ";
    AnotherSpin = "để quay thêm một lần nữa.";
    InviteFriends = "Mời bạn bè";
    InviteAFriend = "Mời một người bạn";
    GiveAwayWithoutLottery = "Tặng quà không cần quay số";
    ReferralPrizePool = "Quỹ giải thưởng giới thiệu";
    ForYouAndYourFriend = "Cho bạn và bạn của bạn";
    ListOYourFriends = "Danh sách bạn bè của bạn";
    Copied = "Đã sao chép!";
    //
    DailyTasks = "Nhiệm vụ hàng ngày";
    Claim = "Nhận";
    DailyLogIn = "Đăng nhập hàng ngày";
    CompleteYourDaily = "Hoàn thành nhiệm vụ hàng ngày để nhận thưởng!";
    Day = "Ngày";
    EarnMoreCoins = "Kiếm thêm tiền xu";
    Follow = "Theo dõi";
    Subscribe = "Đăng ký";
    Join = "Tham gia";
    //
    HowToEarn = "Làm thế nào để kiếm?";
    GetReadyForkWikzCoin = "Hãy sẵn sàng cho đồng GET";
    WikZAppLaunched = "Ứng dụng GetFi đã ra mắt!";
    NOTWIKZReferralPool = "Quỹ giới thiệu NOT và GET";
    WikZCoinsBigAirdrop = "Airdrop lớn của đồng GET";
    ExchangeListing = "Niêm yết trên sàn";
    Soon = "Sắp tới";
    //
    Vibrate = "Rung";
    Language = "Ngôn ngữ";
    Dashboard = "Bảng điều khiển";
    Orders = "Đơn hàng";
    Support = "Hỗ trợ";
    TelegramChannel = "Kênh Telegram";
    //
    YourBalance = "Số dư của bạn";
    Loading = "Đang tải";
    Spinning = "Đang quay";
    Spin = "Quay";
}

export type LanguageProps = keyof LanguageKeysVI;
export default LanguageKeysVI;

