export class LanguageKeysDE {
    SpinTheWheel="Dreh das Rad";
    PleaseWait="Bitte warten";
    Hours="Stunden";
    Earn="Verdienen";
    Frens="Freunde";
    Wikz="GetFi";
    Tasks="Aufgaben";
    Airdrops="Airdrops";
    //
    GiveItAWhirl="Versuche dein Glück!";
    DonTForget="Vergiss nicht, du kannst in 8 Stunden wiederkommen";
    AnotherSpin="für eine weitere Drehung.";
    InviteFriends="Lade deine Freunde ein";
    InviteAFriend="Lade einen Freund ein";
    GiveAwayWithoutLottery="Geschenk ohne Verlosung";
    ReferralPrizePool="Empfehlungs-Preispool";
    ForYouAndYourFriend="Für dich und deinen Freund";
    ListOYourFriends="Liste deiner Freunde";
    Copied="Kopiert!";
    //
    DailyTasks="Tägliche Aufgaben";
    Claim="Beanspruchen";
    DailyLogIn="Täglicher Login";
    CompleteYourDaily="Erledige deine täglichen Aufgaben und hole dir Belohnungen!";
    Day="Tag";
    EarnMoreCoins="Verdiene mehr Münzen";
    Follow="Folgen";
    Subscribe="Abonnieren";
    Join="Beitreten";
    //
    HowToEarn="Wie man verdient";
    GetReadyForkWikzCoin="Bereit für GET Coins";
    WikZAppLaunched="GetFi App gestartet!";
    NOTWIKZReferralPool="NOT und GET Empfehlungs-Pool";
    WikZCoinsBigAirdrop="GET Coins großer Airdrop";
    ExchangeListing="Börsennotierung";
    Soon="Bald";
    //
    Vibrate="Vibrieren";
    Language="Sprache";
    Dashboard="Dashboard";
    Orders="Bestellungen";
    Support="Support";
    TelegramChannel="Telegram-Kanal";
    //
    YourBalance="Dein Guthaben";
    Loading="Lädt";
    Spinning="Dreht sich";
    Spin="Drehen";
}

export type LanguageProps = keyof LanguageKeysDE;
export default LanguageKeysDE;

