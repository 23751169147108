export class LanguageKeysRU {
    SpinTheWheel = "Крутить колесо";
    PleaseWait = "Пожалуйста, подождите";
    Hours = "Часы";
    Earn = "Заработать";
    Frens = "Друзья";
    Wikz = "GetFi";
    Tasks = "Задания";
    Airdrops = "Эирдропы";
    //
    GiveItAWhirl = "Испытай удачу!";
    DonTForget = "Не забудьте, вы можете вернуться через 8 часов";
    AnotherSpin = "для следующего вращения.";
    InviteFriends = "Пригласить друзей";
    InviteAFriend = "Пригласить друга";
    GiveAwayWithoutLottery = "Подарок без лотереи";
    ReferralPrizePool = "Призовой фонд реферальной программы";
    ForYouAndYourFriend = "Для вас и вашего друга";
    ListOYourFriends = "Список ваших друзей";
    Copied = "Скопировано!";
    //
    DailyTasks = "Ежедневные задания";
    Claim = "Получить";
    DailyLogIn = "Ежедневный вход";
    CompleteYourDaily = "Выполняйте ежедневные задания и получайте награды!";
    Day = "День";
    EarnMoreCoins = "Заработайте больше монет";
    Follow = "Подписаться";
    Subscribe = "Подписаться";
    Join = "Присоединиться";
    //
    HowToEarn = "Как заработать?";
    GetReadyForkWikzCoin = "Будьте готовы к GET Coin";
    WikZAppLaunched = "Приложение GetFi запущено!";
    NOTWIKZReferralPool = "Реферальный фонд NOT и GET";
    WikZCoinsBigAirdrop = "Большой эирдроп GET Coin";
    ExchangeListing = "Листинг на бирже";
    Soon = "Скоро";
    //
    Vibrate = "Вибрация";
    Language = "Язык";
    Dashboard = "Панель управления";
    Orders = "Заказы";
    Support = "Поддержка";
    TelegramChannel = "Канал Telegram";
    //
    YourBalance = "Ваш баланс";
    Loading = "Загрузка";
    Spinning = "Вращение";
    Spin = "Крутить";
}

export type LanguageProps = keyof LanguageKeysRU;
export default LanguageKeysRU;

