import config from "../config";

import axios from "axios";
import WebApp from "@twa-dev/sdk";

const authClient = axios.create({
  baseURL: config.earnUrl,
});

export async function login() {
  const res = await authClient.post("/telegram/auth", {
  initData: WebApp.initData,
  //initData:"user=%7B%22id%22%3A1479523398%2C%22first_name%22%3A%22fatih%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22qraxiss%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=-8728542451903155729&chat_type=private&start_param=1479523398&auth_date=1720433482&hash=29d14fd7dbbede7433b77576e3ff37d0064ed198d7b6b55c638a9d993daabde5",
  });

  return res.data;
}

export default authClient;
